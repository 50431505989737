import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import * as z from 'zod';

import { useDictionaryClient } from '../network/ApiProvider';
import { AppLanguage, defaultAppLanguage, useAppLanguage } from '../i18n';
import { AxiosApiError } from '../network/types';

export const createDictionaryCacheKey = (name: string) => ['dictionary', name];

const dictionaryItemSchema = z.object({
  code: z.string().min(1),
  value: z.string().min(1),
  description: z.string().optional(),
});

const languageSchema = z.union([
  z.literal('EN'),
  z.literal('IT'),
  z.literal('SI'),
  z.literal('MK'),
  z.literal('PT'),
  z.literal('DE'),
  z.literal('SQ'),
  z.literal('LT'),
  z.literal('SR'),
  z.literal('HR'),
]);

export type DictionaryQuery = ReturnType<typeof useDictionaryQuery>;

export const entriesByLanguageSchema = z.record(
  languageSchema,
  z.array(dictionaryItemSchema),
);

export const entriesByLanguageSchemaArray = z.array(dictionaryItemSchema);

export const dictionaryResponseSchema = z.object({
  name: z.string().optional(),
  entriesByLanguage: z.record(languageSchema, z.array(dictionaryItemSchema)),
});

export type QueryResult = z.infer<typeof entriesByLanguageSchema>;

export const useDictionaryQuery = (
  dictionaryName: string,
  opts?: UseQueryOptions<QueryResult, AxiosApiError>,
) => {
  const dictionaryClient = useDictionaryClient();
  const [appLanguage] = useAppLanguage();
  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await dictionaryClient.get(
      `/api/dictionaries/${dictionaryName}`,
      {
        signal,
        headers: {
          'Accept-Language': `*`,
        },
      },
    );
    const currentLanguageKey =
      appLanguage.toUpperCase() as Uppercase<AppLanguage>;
    const defaultLanguageKey =
      defaultAppLanguage.toUpperCase() as Uppercase<AppLanguage>;
    const { entriesByLanguage } = dictionaryResponseSchema.parse(data);
    const entries =
      entriesByLanguage[currentLanguageKey] ??
      entriesByLanguage[defaultLanguageKey];
    invariant(
      entries,
      `dictionary ${dictionaryName} did not contain an entriesByLanguage entry for neither ${currentLanguageKey} nor ${defaultLanguageKey}`,
    );
    return entriesByLanguage;
  };
  return useQuery<QueryResult, AxiosApiError>(
    createDictionaryCacheKey(dictionaryName),
    queryFn,
    opts,
  );
};
