import { FC } from 'react';
import { Heading, VStack, Text } from '@chakra-ui/react';

import { useTranslation } from '../i18n';
import { HeroPage } from '../ui-components/HeroPage';

import { ForgotPasswordForm } from './forgotPasswordPage/ForgotPasswordForm';

export type ForgotPasswordPageProps = {
  skipRecaptcha?: boolean;
};

export const ForgotPasswordPage: FC<ForgotPasswordPageProps> = (props) => {
  const { t } = useTranslation();

  const renderHeader = () => (
    <VStack textAlign="center">
      <Heading>{t('forgotPassword.heading', 'Forgot your password?')}</Heading>
      <Text>
        {t('forgotPassword.subheading', 'We’ll send you a recovery link.')}
      </Text>
    </VStack>
  );

  return (
    <HeroPage>
      <VStack spacing={3} height="full">
        {renderHeader()}
      </VStack>

      <ForgotPasswordForm skipRecaptcha={props.skipRecaptcha || false} />
    </HeroPage>
  );
};
