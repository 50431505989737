import { FC, ReactNode, useCallback } from 'react';
import {
  AuthProvider as AP,
  useSignOut as useSO,
  useSignIn as useSI,
} from 'react-auth-kit';
import { createRefresh, useAuthUser } from 'react-auth-kit';
import * as z from 'zod';
import invariant from 'tiny-invariant';

import { useRefWithInit } from './hooks/useRefWithInit';
import { createApi } from './network/api';
import { appConfig } from './appConfig';
import { secondsToMinutes } from './timeHelpers';

export * from 'react-auth-kit';

export const OAuthTokenResponseSchema = z.object({
  access_token: z.string().min(1),
  token_type: z.string(),
  refresh_token: z.string().min(1),
  expires_in: z.number(),
});

export type OAuthTokenResponse = z.infer<typeof OAuthTokenResponseSchema>;

export const refreshTokenTTLMins = 60;
export const authFlowsBasicAuthToken =
  'Basic ZXBwLXVpLWNsaWVudDplcHAtdWktc2VjcmV0';

export type UserType =
  // Signed-in with MyWorld SSO
  | 'internal'
  // Signed-in with Mediabox EPP login page
  | 'external';

export type UserAuthState = {
  userType: UserType;
  permissions: string[];
};

export type AccessTokenType = {
  scope: string[];
};

type SignInArg = Parameters<ReturnType<typeof useSI>>[0] & {
  authState: UserAuthState;
};

export const useSignIn = () => {
  const signIn = useSI();

  return useCallback((options: SignInArg) => signIn(options), [signIn]);
};

export const useAuthState = () => {
  return useAuthUser() as () => UserAuthState;
};

const defaultRefreshAuthTokenIntervalMs = 1780;

const createRefreshApi = () => {
  const api = createApi(appConfig.eppBackendServiceBaseUrl);

  const authTokenRefreshIntervalMs = appConfig.refreshAuthTokenIntervalMs;

  const interval =
    authTokenRefreshIntervalMs > 0
      ? secondsToMinutes(authTokenRefreshIntervalMs)
      : // eslint-disable-next-line no-console
        (console.warn(
          `Illegal auth token refresh interval supplied, using the default.`,
        ),
        secondsToMinutes(defaultRefreshAuthTokenIntervalMs));

  return createRefresh({
    interval,

    refreshApiCallback: async ({ refreshToken }) => {
      try {
        invariant(refreshToken, 'refresh token missing while trying to use it');

        const formData = new URLSearchParams();

        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', refreshToken);
        formData.append('scope', 'read');

        const { data } = await api.post('/oauth/token', formData, {
          headers: {
            Authorization: authFlowsBasicAuthToken,
          },
        });

        const {
          access_token: newAuthToken,
          refresh_token: newRefreshToken,
          expires_in: expirationTimeMs,
        } = OAuthTokenResponseSchema.parse(data);

        const newAuthTokenExpireInMins = secondsToMinutes(expirationTimeMs);

        return {
          isSuccess: true,
          newAuthToken,
          newAuthTokenExpireIn: newAuthTokenExpireInMins,
          newRefreshToken,
          newRefreshTokenExpiresIn: refreshTokenTTLMins,
        };
      } catch (error) {
        return {
          isSuccess: false,
          newAuthToken: '',
        };
      }
    },
  });
};

export type AuthProviderProps = { children?: ReactNode };

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;

  const { current: refreshApi } = useRefWithInit(createRefreshApi);

  return (
    <AP
      authType="localstorage"
      authName="_auth"
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === 'https:'}
      refresh={refreshApi}
    >
      {children}
    </AP>
  );
};

export const useSignOut = useSO;
