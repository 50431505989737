import { AlertIcon, Center, Stack, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { jwtDecode } from 'jwt-decode';

import { AccessTokenType, useSignIn } from '../AuthProvider';
import { useSearchParam } from '../hooks/useSearchParam';
import { secondsToMinutes } from '../timeHelpers';
import { ApiErrorResponse } from '../network/types';
import { Alert } from '../ui-components/Alert';
import { FillingLoader } from '../ui-components/FillingLoader';
import { HeroPage } from '../ui-components/HeroPage';
import { LinkButton } from '../ui-components/LinkButton';
import { NetworkErrorAlert } from '../ui-components/NetworkErrorAlert';

import { useSSOGetAuthTokenMutation } from './myWorldSSOReturnPage/useSSOGetAuthTokenMutation';

export type MyWorldSSOReturnPageProps = {};

// TODO: translations
export const MyWorldSSOReturnPage: FC<MyWorldSSOReturnPageProps> = (props) => {
  const { t } = useTranslation();

  const [[authCode]] = useSearchParam('code');

  const signIn = useSignIn();
  const [isSignInError, setIsSignInError] = useBoolean(false);

  const authTokenMutation = useSSOGetAuthTokenMutation({
    onSuccess: (tokenData) => {
      const handleSignInError = () => {
        setIsSignInError(true);
      };

      try {
        const {
          access_token: token,
          refresh_token: refreshToken,
          expires_in: expiresInMs,
          token_type: tokenType,
        } = tokenData;

        const decodedAccessToken = jwtDecode<AccessTokenType>(token);

        const expiresInMins = secondsToMinutes(expiresInMs);

        if (
          !signIn({
            token,
            refreshToken,
            expiresIn: expiresInMins,
            refreshTokenExpireIn: 60,
            tokenType,
            authState: {
              userType: 'internal',
              permissions: decodedAccessToken.scope,
            },
          })
        ) {
          handleSignInError();
          return;
        }
      } catch {
        handleSignInError();
      }
    },
    onError: (error) => {
      const errorResponse = (error as AxiosError<ApiErrorResponse>)?.response;

      if (!errorResponse) {
        return;
      }

      if (
        errorResponse.data?.type ===
          'urn:problem-type:user:terms-not-accepted' &&
        (errorResponse.headers.Location || errorResponse.headers.location)
      ) {
        window.location.href =
          errorResponse.headers.Location || errorResponse.headers.location;
      }
    },
  });

  useEffect(() => {
    if (!authCode) {
      return;
    }

    authTokenMutation.mutate({ authCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, authTokenMutation.mutate]);

  if (!authCode) {
    return (
      <HeroPage>
        <Center>
          <Alert status="error">
            <AlertIcon />
            <Stack>
              <Text>{t('ssoReturnPage.authCodeMissing')}</Text>
              <LinkButton to="/login">
                {t('forgotPasswordVerifyEmail.login', 'LOGIN')}
              </LinkButton>
            </Stack>
          </Alert>
        </Center>
      </HeroPage>
    );
  }

  if (isSignInError) {
    return (
      <HeroPage>
        <Center>
          <Alert status="error">
            <AlertIcon />
            <Stack>
              <Text>{t('ssoReturnPage.signInError')}</Text>
              <LinkButton to="/login">
                {t('forgotPasswordVerifyEmail.login', 'LOGIN')}
              </LinkButton>
            </Stack>
          </Alert>
        </Center>
      </HeroPage>
    );
  }

  if (!authTokenMutation.isError) {
    return (
      <HeroPage>
        <Center>
          <FillingLoader message={t('ssoReturnPage.gettingCredentials')} />
        </Center>
      </HeroPage>
    );
  }

  const generalNetworkErrorContent = (
    <HeroPage>
      <Center>
        <NetworkErrorAlert networkError={authTokenMutation.error}>
          <Stack>
            <Text>{t('ssoReturnPage.generalError')}</Text>
            <LinkButton to="/login">
              {t('forgotPasswordVerifyEmail.login', 'LOGIN')}
            </LinkButton>
          </Stack>
        </NetworkErrorAlert>
      </Center>
    </HeroPage>
  );

  const errorResponse = (
    authTokenMutation.error as AxiosError<ApiErrorResponse>
  )?.response;

  if (!errorResponse) {
    return generalNetworkErrorContent;
  }

  const { data } = errorResponse;

  const { type } = data ?? {};

  if (type === 'urn:problem-type:user:country-not-supported') {
    return (
      <HeroPage>
        <Center>
          <NetworkErrorAlert networkError={authTokenMutation.error}>
            <Stack>
              <Text>{t('ssoReturnPage.countryNotSupported')}</Text>
              <LinkButton to="/login">
                {t('forgotPasswordVerifyEmail.login', 'LOGIN')}
              </LinkButton>
            </Stack>
          </NetworkErrorAlert>
        </Center>
      </HeroPage>
    );
  }

  if (type === 'urn:problem-type:user:not-partner-not-allowed') {
    return (
      <HeroPage>
        <Center>
          <NetworkErrorAlert networkError={authTokenMutation.error}>
            <Stack>
              <Text>{t('ssoReturnPage.notAPartnerAccountError')}</Text>
              <LinkButton to="/login">
                {t('forgotPasswordVerifyEmail.login', 'LOGIN')}
              </LinkButton>
            </Stack>
          </NetworkErrorAlert>
        </Center>
      </HeroPage>
    );
  }

  if (type === 'urn:problem-type:user:terms-not-accepted') {
    return (
      <HeroPage>
        <Center>
          <FillingLoader message={t('ssoReturnPage.gettingCredentials')} />
        </Center>
      </HeroPage>
    );
  }

  return generalNetworkErrorContent;
};
