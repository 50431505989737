import { useToast } from '@chakra-ui/react';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createContext, FC, ReactNode, useContext } from 'react';
import { useLatest } from 'react-use';
import invariant from 'tiny-invariant';
import { UNAUTHORIZED } from 'http-status';
import { identity } from 'ramda';

import { useTranslation } from '../i18n';
import { useAuthHeader, useSignOut } from '../AuthProvider';
import { useRefWithInit } from '../hooks/useRefWithInit';
import { appConfig } from '../appConfig';

import { createApi } from './api';
import { AxiosApiError } from './types';

const defaultValue = {};

type ProviderValue = {
  api: AxiosInstance;
  fileUploadClient: AxiosInstance;
  dictionaryClient: AxiosInstance;
  statisticsClient: AxiosInstance;
  accountClient: AxiosInstance;
};

const ApiContext = createContext<ProviderValue>(defaultValue as ProviderValue);

export const ApiProvider: FC<{ children?: ReactNode }> = (props) => {
  const { children = null } = props;

  const toast = useToast();
  const { t } = useTranslation();

  const getAuthHeaderRef = useLatest(useAuthHeader());

  const signOut = useSignOut();

  const { current: api } = useRefWithInit<ProviderValue>(() => {
    const addRequestAuthHeaderInterceptor = (config: AxiosRequestConfig) => {
      const authHeader = getAuthHeaderRef.current();

      if (authHeader) {
        config.headers = { Authorization: authHeader, ...config.headers };
      }

      return config;
    };

    const logoutOnUnauthorizedResponseStatusInterceptor = (
      error: AxiosApiError,
    ) => {
      if (error?.response?.status === UNAUTHORIZED && error.config.autoLogout) {
        toast({
          title: t('signInAgain', 'You have been logged out, please Log In'),
          status: 'error',
          isClosable: true,
        });

        signOut();
      }

      throw error;
    };

    const api = createApi(appConfig.eppBackendServiceBaseUrl, {
      autoLogout: true,
    });
    const fileUploadClient = createApi(appConfig.fileServiceBaseUrl, {
      autoLogout: true,
    });
    const dictionaryClient = createApi(appConfig.dictionaryServiceBaseUrl, {
      autoLogout: true,
    });
    const statisticsClient = createApi(appConfig.statisticsServiceBaseUrl, {
      autoLogout: true,
    });
    const accountClient = createApi(appConfig.accountServiceBaseUrl, {
      autoLogout: true,
    });

    [
      api,
      fileUploadClient,
      dictionaryClient,
      statisticsClient,
      accountClient,
    ].forEach((client) => {
      client.interceptors.request.use(addRequestAuthHeaderInterceptor);
      client.interceptors.response.use(
        identity,
        logoutOnUnauthorizedResponseStatusInterceptor,
      );
    });

    return {
      api,
      fileUploadClient,
      dictionaryClient,
      statisticsClient,
      accountClient,
    };
  });

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

const useApiContext = () => useContext<ProviderValue>(ApiContext);

export const useApi = () => {
  const value = useApiContext();

  invariant(
    value !== defaultValue,
    `"useApi" hook used outside of its' provider`,
  );

  return value.api;
};

export const useFileUploadClient = () => {
  const value = useApiContext();

  invariant(
    value !== defaultValue,
    `"useFileUploadClient" hook used outside of its' provider`,
  );

  return value.fileUploadClient;
};

export const useDictionaryClient = () => {
  const value = useApiContext();

  invariant(
    value !== defaultValue,
    `"useDictionaryClient" hook used outside of its' provider`,
  );

  return value.dictionaryClient;
};

export const useStatisticsClient = () => {
  const value = useApiContext();

  invariant(
    value !== defaultValue,
    `"useStatisticsClient" hook used outside of its' provider`,
  );

  return value.statisticsClient;
};

export const useAccountClient = () => {
  const value = useApiContext();

  invariant(
    value !== defaultValue,
    `"useAccountClient" hook used outside of its' provider`,
  );

  return value.accountClient;
};
