import {
  Heading,
  VStack,
  Text,
  Stack,
  Radio,
  Center,
  Wrap,
  Input,
} from '@chakra-ui/react';
import { FormEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';

import { HookFormRadioGroup } from '../../form-components/HookFormRadioGroup';
import { useTranslation } from '../../i18n';
import { Button } from '../../ui-components/Button';
import { OnboardingFormFields } from '../OnboardingPage';

export const CampaignTargetInfoStep = (props: {
  onSubmit: () => void;
  onBack: () => void;
}) => {
  const { t } = useTranslation();

  const { control, watch, register } = useFormContext<OnboardingFormFields>();

  const onSubmit: FormEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    props.onSubmit();
  };

  const campaignTarget = watch('campaignTarget');

  return (
    <Stack spacing={8}>
      <VStack textAlign="center">
        <Heading size="lg">
          {t(
            'onboardingPage.campaignTargetQuestion',
            'Who will you build campaigns for?',
          )}
        </Heading>
        <Text>
          {t(
            'onboardingPage.questionaireExplanation',
            'We’ll help you get started based on your answers.',
          )}
        </Text>
      </VStack>

      <Stack as="form" spacing={6} onSubmit={onSubmit}>
        <HookFormRadioGroup
          name="campaignTarget"
          labelProps={{
            hidden: true,
            children: t(
              'onboardingPage.campaignTargetsLabel',
              'Campaign targets',
            ),
          }}
          controllerProps={{ control, rules: { required: true } }}
        >
          <Center>
            <Stack spacing={4}>
              <Radio value="OWN_BUSINESS">
                {t(
                  'onboardingPage.campaignTargetsMyOwnBusiness',
                  'My own business',
                )}
              </Radio>
              <Radio value="EMPLOYER_COMPANY">
                {t(
                  'onboardingPage.campaignTargetsEmployer',
                  'The company I work for',
                )}
              </Radio>
              <Radio value="FREELANCER_CLIENTS">
                {t(
                  'onboardingPage.campaignTargetsFreelancer',
                  'Clients, as a freelancer',
                )}
              </Radio>
              <Radio value="AGENCY_CLIENTS">
                {t(
                  'onboardingPage.campaignTargetsAgency',
                  'Clients, as part of an agency',
                )}
              </Radio>

              <Stack>
                <Radio value="OTHER">
                  {t('onboardingPage.campaignTargetsOther', 'Other')}
                </Radio>
                {campaignTarget === 'OTHER' && (
                  <Input
                    variant="flushed"
                    placeholder={t(
                      'onboardingPage.campaignTargetsOtherPlaceholder',
                      'Tell us more here',
                    )}
                    {...register('campaignTargetAdditionalDetails', {
                      shouldUnregister: true,
                    })}
                  />
                )}
              </Stack>
            </Stack>
          </Center>
        </HookFormRadioGroup>

        <Wrap width="full" minWidth="600px">
          <Button onClick={props.onBack} flex={1} variant="outline">
            {t('back', 'BACK')}
          </Button>

          <Button type="submit" isDisabled={!campaignTarget} flex={1}>
            {t('continue', 'CONTINUE')}
          </Button>
        </Wrap>
      </Stack>
    </Stack>
  );
};
