import { Heading, VStack, Text, Stack, Radio, Center } from '@chakra-ui/react';
import { FormEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';

import { HookFormRadioGroup } from '../../form-components/HookFormRadioGroup';
import { useTranslation } from '../../i18n';
import { Button } from '../../ui-components/Button';
import { OnboardingFormFields } from '../OnboardingPage';

export const CompanyInfoStep = (props: { onSubmit: () => void }) => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<OnboardingFormFields>();

  const onSubmit: FormEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    props.onSubmit();
  };

  const companySize = watch('companySize');

  return (
    <Stack spacing={8}>
      <VStack textAlign="center">
        <Heading size="lg">
          {t('onboardingPage.companyInfoTitle', 'Tell us about your company')}
        </Heading>
        <Text>
          {t(
            'onboardingPage.companyInfoQuestionExplanation',
            "We'll help you get started based on your answers",
          )}
        </Text>
      </VStack>

      <Stack as="form" spacing={6} onSubmit={onSubmit}>
        <HookFormRadioGroup
          name="companySize"
          labelProps={{
            children: t('onboardingPage.companyInfoSizeLabel', 'Company size'),
          }}
          controllerProps={{ control, rules: { required: true } }}
        >
          <Center>
            <Stack spacing={4}>
              <Radio value="MICRO">
                {t('onboardingPage.companyInfoSizeMicro', '1-50 employees')}
              </Radio>
              <Radio value="SMALL">
                {t('onboardingPage.companyInfoSizeSmall', '51-200 employees')}
              </Radio>
              <Radio value="MEDIUM">
                {t(
                  'onboardingPage.companyInfoSizeMedium',
                  '201-1001 employees',
                )}
              </Radio>
              <Radio value="BIG">
                {t(
                  'onboardingPage.companyInfoSizeBig',
                  'more than 1001 employees',
                )}
              </Radio>
            </Stack>
          </Center>
        </HookFormRadioGroup>

        <Button type="submit" isDisabled={!companySize}>
          {t('continue', 'CONTINUE')}
        </Button>
      </Stack>
    </Stack>
  );
};
