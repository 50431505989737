import { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Path,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useLatest, usePrevious } from 'react-use';
import { lazy, Suspense } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIdleTimer } from 'react-idle-timer';
import * as Sentry from '@sentry/react';

import { RequireAuth, useIsAuthenticated, useSignOut } from './AuthProvider';
import { LoginPage } from './pages/LoginPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ForgotPasswordVerifyEmailPage } from './pages/ForgotPasswordVerifyEmailPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { OnboardingPage } from './pages/OnboardingPage';
import { RegistrationVerifyEmailPage } from './pages/RegistrationVerifyEmailPage';
import { RegistrationEmailVerifiedPage } from './pages/RegistrationEmailVerifiedPage';
import { useAuthUser } from './useAuthUser';
import { FullScreenLoader } from './ui-components/FullScreenLoader';
import { GeneralErrorPage } from './GeneralErrorPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { AppPage } from './ui-components/AppPage';
import { useTranslation } from './i18n';
import { MyWorldSSOReturnPage } from './pages/MyWorldSSOReturnPage';
import { appConfig } from './appConfig';
import AcceptCampaignPage from './pages/AcceptCampaignPage';
import { ConfirmAccountTransferPage } from './pages/ConfirmAccountTransferPage';

const LOGOUT_TIMEOUT = 30 * 60 * 1000;

const CampaignPage = lazy(
  () =>
    import(
      /* webpackChunkName: "campaign-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default"] */
      /* webpackPrefetch: true */
      './pages/CampaignPage'
    ),
);

const CampaignsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "campaign-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default"] */
      /* webpackPrefetch: true */
      './pages/CampaignsPage'
    ),
);

const CreditsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "credits-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default"] */
      /* webpackPrefetch: true */
      './pages/CreditsPage'
    ),
);

const ImprintPage = lazy(
  () =>
    import(
      /* webpackChunkName: "imprint-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default"] */
      /* webpackPrefetch: true */
      './pages/ImprintPage'
    ),
);

const PrivacyPolicyPage = lazy(
  () =>
    import(
      /* webpackChunkName: "privacy-policy-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default"] */
      /* webpackPrefetch: true */
      './pages/PrivacyPolicyPage'
    ),
);

const HomePage = lazy(
  () =>
    import(
      /* webpackChunkName: "home-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default"] */
      /* webpackPrefetch: true */
      './pages/HomePage'
    ),
);

const SettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "settings-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default"] */
      /* webpackPrefetch: true */
      './pages/SettingsPage'
    ),
);

const PublicPage = () => {
  const isAuthenticated = useIsAuthenticated()();
  const location = useLatest(useLocation());
  if (location.current.pathname.startsWith('/public')) {
    return <Outlet />;
  }
  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

const PrivatePage = () => {
  const { t } = useTranslation();

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    Object.assign(window, { logout: useSignOut() });
  }

  const { isFetching, isLoading, isError, data: authUser } = useAuthUser();

  useEffect(() => {
    if (authUser) {
      Sentry.configureScope((scope) => {
        const {
          id,
          info: { email, firstName, lastName },
        } = authUser;
        const user: Sentry.User = {
          id,
          email,
          username: `${firstName} ${lastName}`,
        };
        scope.setUser(user);
      });
    }
  }, [authUser]);

  if (isFetching || isLoading) {
    return (
      <FullScreenLoader
        message={t('app.loadingUserData', 'Loading user data, please wait…')}
      />
    );
  }

  if (isError) {
    return <GeneralErrorPage />;
  }

  if (authUser.status === 'ONBOARDING_REQUIRED') {
    return <OnboardingPage />;
  }

  return (
    <AppPage>
      <Outlet />
    </AppPage>
  );
};

const useUnauthenticationCleanup = () => {
  const queryClient = useQueryClient();

  const isAuthenticated = useIsAuthenticated()();
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  useEffect(() => {
    if (prevIsAuthenticated && !isAuthenticated) {
      queryClient.clear();
    }
  }, [isAuthenticated, prevIsAuthenticated, queryClient]);
};

const useRestoreAuthLocation = () => {
  const isAuthenticated = useIsAuthenticated()();
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  const location = useLatest(useLocation());
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && prevIsAuthenticated === false) {
      const locationState = location.current.state as
        | Record<'from', Path>
        | undefined;

      if (locationState?.from) {
        navigate(locationState.from.pathname);
        return;
      }

      navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, location, prevIsAuthenticated]);
};

export const App = () => {
  useUnauthenticationCleanup();
  useRestoreAuthLocation();

  const signOut = useSignOut();

  useIdleTimer({
    timeout: LOGOUT_TIMEOUT,
    onIdle: signOut,
    debounce: 500,
  });

  if (!localStorage.getItem('isDefaultLanguage')) {
    localStorage.setItem('isDefaultLanguage', 'true');
  }

  const renderPrivateRoutes = () => (
    <>
      <Route index element={<HomePage />} />

      <Route path="credits">
        <Route index element={<CreditsPage />} />
        <Route
          path="accept-transfer"
          element={<ConfirmAccountTransferPage />}
        />
      </Route>
      <Route path="clients" element={<HomePage />} />

      <Route path="settings/*" element={<Outlet />}>
        <Route index element={<Navigate to="general" />} />
        <Route path=":section" element={<SettingsPage />} />
        <Route path="*" element={<Navigate to="general" />} />
      </Route>

      <Route path="campaigns">
        <Route index element={<CampaignsPage />} />
        <Route path=":campaignId/*" element={<CampaignPage />} />
      </Route>
      <Route path="accept-campaign">
        <Route path=":campaignId/*" element={<AcceptCampaignPage />} />
      </Route>

      <Route
        path="private/imprint"
        element={<ImprintPage withHomeBanner={false} />}
      />
      <Route
        path="private/privacy-policy"
        element={<PrivacyPolicyPage withHomeBanner={false} />}
      />

      <Route path="*" element={<NotFoundPage />} />
    </>
  );

  const renderPublicRoutes = () => (
    <>
      <Route path="register">
        <Route index element={<RegistrationPage />} />
        <Route path="verify-email" element={<RegistrationVerifyEmailPage />} />
        <Route
          path="email-verified"
          element={<RegistrationEmailVerifiedPage />}
        />
      </Route>

      <Route path="login" element={<LoginPage />} />

      <Route path="forgot-password">
        <Route index element={<ForgotPasswordPage />} />

        <Route
          path="verify-email"
          element={<ForgotPasswordVerifyEmailPage />}
        />
      </Route>

      <Route path="internal-sso-signedin" element={<MyWorldSSOReturnPage />} />

      {process.env.NODE_ENV === 'development' && (
        <Route
          path={`${appConfig.eppBackendServiceBaseUrl.slice(
            1,
          )}/internal/oauth/authorize`}
          element={
            <Navigate to="/internal-sso-signedin?code=mocked-sso-return-code" />
          }
        />
      )}

      <Route
        path="public/imprint"
        element={<ImprintPage withHomeBanner={true} />}
      />
      <Route
        path="public/privacy-policy"
        element={<PrivacyPolicyPage withHomeBanner={true} />}
      />
    </>
  );

  return (
    <Routes>
      <Route
        path="*"
        element={
          <Suspense fallback={<FullScreenLoader />}>
            <PublicPage />
          </Suspense>
        }
      >
        {renderPublicRoutes()}
      </Route>

      <Route path="forgot-password">
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>

      <Route
        path="*"
        element={
          <RequireAuth loginPath="/login">
            <Suspense fallback={<FullScreenLoader />}>
              <PrivatePage />
            </Suspense>
          </RequireAuth>
        }
      >
        {renderPrivateRoutes()}
      </Route>
    </Routes>
  );
};
