import { FC } from 'react';
import { Heading, Stack, Text, VStack } from '@chakra-ui/react';

import { RouterLink } from '../ui-components/Link';
import { HeroPage } from '../ui-components/HeroPage';
import { Trans, useTranslation } from '../i18n';
import { LoginWithMyWorldButton } from '../ui-components/LogInWithMyWorldButton';

import { LoginForm } from './loginPage/LoginForm';

export type LoginPageProps = {};

export const LoginPage: FC<LoginPageProps> = (props) => {
  const { t } = useTranslation();

  const renderHeader = () => (
    <Stack spacing={5}>
      <VStack textAlign="center" height="full">
        <Heading>{t('login.heading', 'Log In')}</Heading>

        <Text>
          <Trans i18nKey="login.registrationPrompt">
            Not a member? <RouterLink to="/register">Register</RouterLink>
          </Trans>
        </Text>
      </VStack>

      <LoginWithMyWorldButton />
    </Stack>
  );

  return (
    <HeroPage>
      {renderHeader()}
      <LoginForm />
    </HeroPage>
  );
};
