import {
  Box,
  ButtonProps,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import {
  NavLink as NL,
  NavLinkProps as NLP,
  Link,
  useLocation,
} from 'react-router-dom';
import { BiLogOut, BiPhotoAlbum } from 'react-icons/bi';
import { useLatest } from 'react-use';

import { useTranslation } from '../i18n';
import { HomeIcon } from '../icons/HomeIcon';
import { CreditsIcon } from '../icons/CreditsIcon';
import { CampaignsIcon } from '../icons/CampaignsIcon';
import { SettingsIcon } from '../icons/SettingsIcon';
import { id } from '../functionHelpers';
import { CampaignDisplayState } from '../features/campaigns';
import supportContactEmailIcon from '../assets/support_contact_email_icon.svg';
import { useSignOut } from '../AuthProvider';

import { BrandLogo } from './BrandLogo';
import { Button } from './Button';
import { Footer } from './Footer';
import { LanguageFlags } from './LanguageFlags';

export type AppPageProps = PropsWithChildren<{}>;

type NavLinkType = {
  to: string;
  reloadDocument?: boolean;
  linkProps?: Omit<NLP, 'to'>;
  isActivable?: boolean;
};

const NavLink = ({
  to,
  leftIcon,
  linkProps,
  reloadDocument = false,
  isActivable = true,
  ...buttonProps
}: NavLinkType & ButtonProps) => {
  const baseProps: ButtonProps = {
    borderRadius: 'md',
    variant: 'ghost',
    as: 'span',
    width: 'full',
    justifyContent: 'flex-start',
    ...buttonProps,
  };

  const { pathname } = useLocation();

  return (
    <NL
      {...linkProps}
      reloadDocument={pathname === '/credits' ? reloadDocument : false}
      to={to}
    >
      {({ isActive }) => {
        return isActivable && isActive ? (
          <Button
            {...baseProps}
            fontWeight="bold"
            color="brand.400"
            leftIcon={
              <HStack>
                <Box
                  background="brand.300"
                  borderRadius="lg"
                  width="4px"
                  paddingY="1px"
                  height="full"
                />
                {leftIcon}
              </HStack>
            }
          />
        ) : (
          <Button
            {...baseProps}
            fontWeight="medium"
            color="gray.600"
            leftIcon={leftIcon}
          />
        );
      }}
    </NL>
  );
};

const SupportContactSection = () => {
  const { t } = useTranslation();

  const contactEmail = 'b2b@atmedia.digital';

  const supportContactHref = `mailto:${contactEmail}`;

  return (
    <Center>
      <Center
        borderRadius="lg"
        background="gray.200"
        paddingY="10%"
        paddingX={['2%', null, '7%']}
        textAlign="center"
        maxWidth="250px"
      >
        <VStack>
          <Heading size="md">
            {t('navbar.supportContactSectionTitle', 'Need help?')}
          </Heading>

          <VStack spacing={0.5}>
            <Text color="gray.600" fontWeight="bold">
              {t(
                'navbar.supportContactSectionInstruction',
                'Get in touch with us at',
              )}
            </Text>

            <ChakraLink href={supportContactHref}>{contactEmail}</ChakraLink>
          </VStack>

          <ChakraLink
            background="brand.500"
            borderRadius="50%"
            _hover={{
              background: 'brand.600',
            }}
            href={supportContactHref}
          >
            <Image src={supportContactEmailIcon} />
          </ChakraLink>
        </VStack>
      </Center>
    </Center>
  );
};

export const AppPage: FC<AppPageProps> = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const signOut = useSignOut();

  const location = useLatest(useLocation());

  const renderNav = () => (
    <Stack
      as="aside"
      height="full"
      background="white"
      borderTopEndRadius="2xl"
      paddingY={3}
      paddingX={3}
      minWidth={[null, '30%', '17%']}
      position="sticky"
      top={0}
    >
      <Stack as="nav" height="full">
        <Box paddingX={4} paddingTop={5} paddingBottom={8} width="60%">
          <Link to="/">
            <BrandLogo />
          </Link>
        </Box>

        <Box flex={1} />

        <Stack>
          <NavLink to="/" leftIcon={<HomeIcon />} linkProps={{ end: true }}>
            {t('navbar.home', 'Home')}
          </NavLink>

          <NavLink
            to={`/campaigns${
              process.env.NODE_ENV === 'test'
                ? ''
                : `?hide=${id<CampaignDisplayState>('inactive')}`
            }`}
            leftIcon={<CampaignsIcon />}
          >
            {t('navbar.campaigns', 'Campaigns')}
          </NavLink>

          <NavLink
            to="/credits"
            reloadDocument={true}
            leftIcon={<CreditsIcon />}
          >
            {t('navbar.credits', 'Credits')}
          </NavLink>

          <NavLink to="/settings" leftIcon={<SettingsIcon />}>
            {t('navbar.settings', 'Settings')}
          </NavLink>

          <NavLink
            to={location.current.pathname}
            isActivable={false}
            onClick={() => {
              window.open(`${process.env.PUBLIC_URL}/whitepaper.pdf`, '_blank');
            }}
            leftIcon={<BiPhotoAlbum />}
          >
            {t('navbar.whitePaper', 'White Paper')}
          </NavLink>

          <NavLink
            to="/"
            isActivable={false}
            onClick={signOut}
            leftIcon={<BiLogOut />}
          >
            {t('logOut', 'Log out')}
          </NavLink>
        </Stack>

        <Stack flex={10} paddingBottom={5}>
          <Spacer />
          <SupportContactSection />
        </Stack>

        <Footer navPrefix={'/private'} />
      </Stack>

      <Spacer />
    </Stack>
  );

  return (
    <Flex height="100vh" overflowY="auto" background="gray.50">
      {renderNav()}

      {/* overflow is controlled properly only if there is any width set */}
      <Box flex={1} width={0}>
        {children}
      </Box>
      <LanguageFlags />
    </Flex>
  );
};
